.btn {
  font-size: 0.8rem !important;
}

.input:checked {
  background-color: linear-gradient(131deg, #2196f3, #9c27b0);
}

.loader-container {
  width: 100%;
  min-height: 40vh;
}

.item {
  background-color: hsl(0deg 0% 100% / 10%);
  max-width: 100% !important;
}

.active {
  color: black !important;
  background-color: white !important;
  background-image: none !important;
}

.input {
  height: 40px !important;
  min-width: 70%!important;
  font-size: 20px;
}

.input:focus::placeholder {
  opacity: 0;
}
