/* ./src/index.css
@tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --text-color: #fff;
  --shadow-color: hsla(210, 40%, 52%, 0.4);
  --btn-color: hsl(210, 80%, 42%);
  --bg-color: #141218;
}
body {
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(368deg, #402a51, #06063f, #2d1e38, #402a51);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.logoH {
  background: linear-gradient(
    89.39deg,
    #ffda2c 65.6%,
    #f6d641 28.99%,
    #feed9d 100.18%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  letter-spacing: 0.2rem;
  font-size: 20px;
}
nav {
  z-index: 1;
  background-color: rgba(29, 21, 40, 0.09) !important;
  backdrop-filter: blur(0.9375rem) !important;
  background: #ddd;
  z-index: 1000;
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
}
.AliCat button {
  z-index: 1;
}
main {
  min-height: 100vh;
  /*background-color: #000357;*/
  padding-top: 85px;
}
footer {
  color: #fff;
  border-top: 0.0625rem solid hsla(0, 0%, 100%, 0.13);
}
h4,
.h4 {
  color: #fff !important;
}
h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}
.card {
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.13);
  border-radius: 14px;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.btnB1 {
  font-size: 1.5rem !important;
}

.card h3 {
  color: #fff;
  font-size: 22px;
}
.buy {
  font-size: 28px !important;
  min-width: 130px;
  padding: 10px;
}
.card h5 {
  font-size: 14px;
}
.nftC {
  max-height: 315px;
  padding: 0.3rem;
}
.nftC:hover {
  box-shadow: 0 9px 9px 0 rgb(115 115 171 / 50%) !important;
}
.styleYield {
  color: #fff !important;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1em;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.3em;
}

.positionDe {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nftC h3 {
  padding: 0;
  margin: 0;
}
.AlignBc {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.tab-content h5 {
  padding: 15px;
}

.bg-primary {
  background-color: #fff !important;
}
.btn-primary {
  color: #fff;
  background-image: linear-gradient(131deg, #2196f3, #9c27b0);
  box-shadow: -3px 0px 5px #03a9f4;
  border-width: 0;

  border-color: #03a9f4;
  border-radius: 14px;
}
.btn-primary:hover {
  background-color: #1976d2;
  border-color: #1976d2;
  color: #fff;
}
.btn-primary-active {
  background-color: #09031c;
  border-color: #fff;
  color: #fff;
  border-radius: 14px;
  zoom: 1.1;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: #1976d2;
  border-radius: 14px;
  color: #fff;
}
.dropdown-menu {
  border-radius: 14px;
  background-color: transparent;
  text-transform: capitalize;
  min-width: 7rem;
  text-align: center;
  padding: 0.2em 0 !important;
}
.dropdown-menu[data-bs-popper] {
  left: auto;
  top: auto;
  right: 0;
}
.dropdown-item {
  color: #fff !important;
  font-size: 12px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #1976d2 !important;
  background-color: transparent;
}

.card a {
  color: #fff !important;
  text-decoration: none !important;
}
.card a:hover {
  color: #1976d2 !important;
}
.pl-0 {
  padding-left: 0;
}
.card-body {
  font-size: 16px;
  text-align: center;
}
.screenD {
  color: rgb(109, 255, 167);
}
.codeD {
  background-color: #fff;
  padding: 0 20px;
  border-radius: 10px;
  color: #09031c !important;
  min-width: 125px;
  text-align: center;
}
.screenD span {
  color: #fff;
  font-size: 16px !important;
}
.list-group-item {
  font-size: 18px !important;
  padding: 10px;
}

.download-pdf {
  font-size: 12px;
}
.screenD h3 {
  padding: 0.5rem 0;
}
.card .card-img-top {
  height: 150px;
  width: 145px;
  border-radius: 14px;
  margin-bottom: 20px;
}

.smallL {
  font-size: 12px;
}
.alogo {
  line-height: 1 !important;
  text-align: center;
}

.form-control:focus {
  border-color: #1976d2 !important;
  background-color: #09031c;
  color: #fff;
}
.navbar {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-primary:disabled {
  color: #fff;
  background-color: #0a3483;
  border-color: #0a3483;
}
.btn-outline-primary {
  border: none;
  color: #fff;
}
.btn-outline-primary:hover {
  background: transparent;
  border: none;
  color: #1976d2;
}
.btn-primary:focus {
  box-shadow: 0 0 5px #0a3483;
}
.btn-primary:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.filterItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
  align-items: center;
}
.filterItem i {
  font-size: 20px;
  color: #fff;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link:hover {
  border-bottom: 2px solid #0fff17;
  color: #0fff17;
  font-weight: 400;
  background-color: transparent;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  font-weight: 400;
  color: #7373ab;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
  color: #000;
  border-color: #fff;
  background: #fff;
}
.nav-link {
  color: #3082f2;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus {
  box-shadow: 0 0 0 0.25rem #3082f280;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.Collapsible {
  /*border-bottom: 1px solid #1976d2;*/
  padding: 20px;
}
.nav-tabs {
  border-bottom: 1px solid transparent;
  font-size: 14px;
}
.navPanel li button {
  font-weight: 600;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #3082f2;
}
.nav-link:focus,
.nav-link:hover {
  color: #ebd673 !important;
}
.btnConn {
  border: 0;
  padding: 5px 10px;
  border-radius: 0.6rem;
  box-shadow: -3px 0px 5px #03a9f4;
  min-width: 100px;

  background-image: linear-gradient(131deg, #2196f3, #9c27b0);
  -webkit-perspective-origin: 100% 50%;
  perspective-origin: 100% 50%;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.btnConn:focus {
  background-color: #1976d2 !important;
  color: #fff !important;
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-dark .navbar-toggler {
  border-color: transparent;
  background: transparent;
}
.priceD {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.priceD h4 {
  color: #fff;
}

h5 {
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.aligntitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.list-group-flush > .list-group-item {
  background-color: transparent !important;
}
.col3 {
  z-index: 1;
}

.d-none {
  display: none;
}
.priceDollar {
  text-overflow: ellipsis;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  font-size: 1rem;
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #ebd673 !important;
}
.imgSpind {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  /*background-color: #7373ab;*/
  border-radius: 20px;
  /*background-image: url(Spin-d.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;*/
}

small {
  font-weight: 300;
  margin: 0px 10px;
}
.p25 {
  padding: 0 25px;
}

.AlignB .btn:focus,
.AliCat .btn:focus {
  color: #fff;
  background: linear-gradient(90deg, rgb(255 245 0 / 50%), rgb(83 39 58 / 80%));
  border-color: rgb(255 245 0 / 50%);
}
select option {
  background: transparent;
}
@media (min-width: 768px) {
  .element-hide-l {
    display: none;
  }
}
.modal {
  padding-left: 0 !important;
}

@media (max-width: 991.98px) {
  /*.feesR .modal-content {
    min-height: 605px!important;
}*/
  .BlocV {
    flex-direction: column !important;
  }
  .feesR {
    backdrop-filter: blur(4rem);
  }
  .modal {
    padding-left: 0 !important;
  }
  .modalDeposit .modal-dialog {
    max-width: 100% !important;
  }
  .alignt {
    display: flex;
    flex-direction: column !important;
    justify-content: space-around !important;
    align-items: center !important;
  }
  .tabStak {
    zoom: 0.75;
  }
  .alignSt {
    flex-direction: column;
    gap: 20px;
  }

  .Cstaking h2 {
    font-size: 18px !important;
  }
  .bgB {
    padding: 2rem 0.5rem !important;
  }
  .BoxInfos {
    display: block !important;
  }
  .hideMobile {
    display: none !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: auto;
    top: auto;
    right: auto;
  }
}
@media (max-width: 1199.89px) {
  .BlocV {
   gap: 10px !important;
  }
}
@media (max-width: 767.98px) {
  .BlocV {
    flex-direction: column !important;
  }
  .MobileP {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .MobileP button {
    min-width: 210px !important;
    font-size: 20px !important;
    padding: 10px 20px !important;
  }

  .tabStak td img {
    width: 35px !important;
  }
  .stackA .stack-value span {
    font-size: 14px !important;
    display: flex;
    align-items: center !important;
    flex-direction: row;
  }
  .stackA .stack-value img {
    height: 35px !important;
  }

  .AliCat {
    gap: 5px !important;
  }

  .tabStak button {
    margin-top: 5px;
  }

  .stackA {
    width: 100% !important;
  }
  .btnClaim {
    font-size: 16px !important;
  }
  .btnTop2,
  .btnTopH {
    min-width: 130px !important;
    font-size: 14px;
  }
  .Cstaking {
    display: inline;
  }
  .bgB {
    padding: 15px 30px;

    background: #033c3c;
    background-color: #09031c;
    padding: 2em 1em;
    border-radius: 20px;
    margin-bottom: 15px;
  }
  .centerS {
    margin: 0 25px !important;
  }

  .adresst {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
  }
  .btnPending {
    margin-right: 0 !important;
  }
  .navbar-brand {
    margin-right: 0.2rem;
  }

  .navbar-collapse {
    padding-bottom: 20px;
  }
  .navbar-dark .navbar-toggler {
    color: #1976d2;
  }
  .navbar-toggler:focus {
    box-shadow: 0 0 0 0.025rem;
  }
  .priceDd {
    text-overflow: ellipsis;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
  }

  /*.AlignB {
    flex-direction: row!important;  
}*/
  .AlignTri {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: baseline !important;
  }
  .filterMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 15px;
  }
  .filterMobile button {
    border: none;
    font-weight: 600;
  }
  .filterMobile button:hover {
    background-color: transparent;
    border: 1px solid #fff;
    padding: 0 20px;
    border-radius: 10px;
    color: #fff !important;
  }
  .element-visible {
    display: block;
  }
  .element-hidden {
    display: none;
  }
  .screenD h4 {
    padding: 0.5rem 0;
    font-size: 18px;
  }

  .padd {
    padding-bottom: 20px;
  }
}

.range-slider__wrap input {
  width: 100%;
  padding: 10px 0;
}
.range-slider__tooltip--bottom {
  display: none;
}
.AlignB {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Collapsible__contentInner {
  padding-top: 20px;
}
.form-control {
  color: #fff;
  background-color: #09031c;
  border: 1px solid #7373ab;
}
.AlignB .btn-primary {
  font-size: 14px;
}
select {
  padding: 5px 10px;
  text-transform: capitalize;
  color: #fff;
  background: #1e1e34;
  border-color: rgb(255 245 0 / 50%);
  border-radius: 8px;
  z-index: 1;
}
input {
  padding: 5px 10px;
  text-transform: capitalize;
  color: #fff;
  background: transparent;
  border-color: #ebd673;
  border-radius: 8px;
  z-index: 1;
  height: 85px;
  font-size: 30px;
  text-align: center;
  max-width: 100px;
}
.BlocV {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.tex{
  min-width: 300px;
}
.BlocV ul li {
  list-style-type: disclosure-closed;
  text-align: left;
}
.btnM {
  padding: 5px 10px;
  text-transform: capitalize;
  color: #fff;
  background: transparent !important;
  border-color: #ebd673;
  border-radius: 8px;
  margin: 0 5px;
}
h2,
h3,
.pc {
  color: #ebd673 !important;
}
.VenteM {
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.13);
  border-radius: 14px;
  background-color: hsla(0, 0%, 100%, 0.1);
  padding: 30px;
  text-align: center;
  margin-top: 20px;
}

.AlignTri {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.CardTokenId {
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
}
.CardTokenDescription {
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  text-overflow: ellipsis;
  width: 94%;
  overflow: hidden;
  max-height: 3rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
/* .CardTokenDescription::after {
  content: "..";
} */
.CardTokenId:hover {
  color: #1976d2 !important;
}
.CardTokenTokenClass {
  margin-left: auto;
  margin-right: 0;
}
.AliCat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 25px 0;
}
/* .modal-dialog {
  top: 30%;
} */
.modal-content {
  background-color: #09031c;
  border-radius: 20px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
.modal-header {
  border-bottom: 1px solid transparent;
  width: inherit;
}
.modal-footer {
  border-top: 1px solid transparent;
}
.modal-dialog .btn-secondary {
  background-color: #7373ab;
  border-color: #7373ab;
  color: #fff;
  border-radius: 10px;
}
.modal-dialog .btn-secondary:hover {
  background-color: #fff;
  border-color: #7373ab;
  color: #09031c;
  border-radius: 10px;
}
.btnPending {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: transparent;
  background-color: transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 14px;
  font-size: 14px;
  border: 1px solid transparent;
  gap: 10px;
}

.btnPending:hover {
  background-color: #1976d2;
  color: #000;
}
.spinner-border {
  width: 1rem;
  height: 1rem;
}
.pend .list-group-item {
  background-color: transparent !important;
}
.d-f-l {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
  color: #fff;
  margin-right: 10px;
}
.titleN .card-title {
  max-width: 100%;
  overflow-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*style pagination */
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #fff;
  border-color: #d9d9d9;
}
.ant-pagination-item-active a {
  color: #fff !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: #fff;
}
.ant-pagination-item {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
}
.ant-pagination-item a:not([href]):not([class]) {
  color: #fff;
  text-decoration: none;
}
.ant-pagination-item a:not([href]):not([class]):hover {
  color: transparent !important;
  text-decoration: none;
}
.ant-pagination-item:hover {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #fff;
  border-color: #fff;
}
.ant-pagination {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Imetamask .modal-footer {
  justify-content: center !important;
}
.pend .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*new*/
.colB {
  color: #fff !important;
  font-weight: 600;
  font-size: 30px;
}
.test button {
  position: initial;

  border: none;
  background: none;
  cursor: pointer;

  font-family: "Source Code Pro";
  font-weight: 900;
  text-transform: uppercase;

  background-color: transparent;

  border-radius: 4px;
  z-index: 0;
  overflow: hidden;
  height: 100%;
}

.test button:focus {
  outline-color: transparent;
  box-shadow: var(--btn-color) 2px 2px 22px;
}

.test .right::after,
.test button::after {
  content: var(--content);
  display: block;
  position: absolute;
  white-space: nowrap;
  padding: 40px 40px;
  pointer-events: none;
}

.test button::after {
  font-weight: 200;
  top: -30px;
  left: -20px;
}

.test .right,
.test .left {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.test .right {
  left: 66%;
}
.test .left {
  right: 66%;
}
.test .right::after {
  top: -30px;
  left: calc(-66% - 20px);

  background-color: var(--bg-color);
  color: transparent;
  transition: transform 0.4s ease-out;
  transform: translate(-90%, 0) rotate(0deg);
}

.test button:hover .right::after {
  transform: translate(-47%, 0) rotate(0deg);
}

.test button .right:hover::after {
  transform: translate(-50%, 0) rotate(-7deg);
}

.test button .left:hover ~ .right::after {
  transform: translate(-50%, 0) rotate(7deg);
}

/* bubbles */
.test button::before {
  content: "";
  pointer-events: none;

  background: radial-gradient(
      circle at 20% 35%,
      #fff 1%,
      #fff 2px,
      var(--text-color) 3px,
      var(--text-color) 4px,
      transparent 4px
    ),
    radial-gradient(
      circle at 50% 30%,
      #fff 2%,
      #fff 2px,
      var(--text-color) 3px,
      var(--text-color) 4px,
      transparent 4px
    ),
    radial-gradient(
      circle at 40% 20%,
      #fff 1.5%,
      #fff 5px,
      var(--text-color) 5px,
      var(--text-color) 6px,
      transparent 6px
    );

  width: 100%;
  height: 300%;
  top: 0;
  left: 0;
  position: absolute;
  animation: bubbles 2s linear infinite both;
}

@keyframes bubbles {
  from {
    transform: translate();
  }
  to {
    transform: translate(0, -66.666%);
  }
}

.svgtest {
  width: 2em;
  height: 2em;
  position: fixed;
  top: 1em;
  left: 1em;
  opacity: 0.8;
}
.Cstaking h2 {
  font-size: 30px;
}
.ANTProgress-numbers-active > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  line-height: 20px;
  height: 61px;
  width: 61px;
  border-radius: 50%;
  background-color: #1976d2;
  margin-bottom: 25px;
  position: relative;
  font-weight: 700;
  color: #00001e;
}
.cercleVC {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.cercleV {
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 16px;
}
.bgBlocAff2 .valeurAnt {
  background: #fff;
  min-width: 191px;
  padding: 0.2em 1em;
  border-radius: 30px;
  color: #09031c;
  font-size: 27px;
  text-align: center;
}
.cerStack {
  position: absolute;
  left: 43%;
  top: -45px;
}
.btnS {
  background: #00001e;
  min-width: 40px;
  padding: 10px;
  border-radius: 7px;
  color: #0fff17;
  margin-right: 10px;
  border: none;
}
.valeurS {
  background: #00001e;
  min-width: 130px;
  padding: 10px;
  border-radius: 7px;
  color: #7373ab;
  margin-right: 10px;
}
.AntBoxInfo > div {
  color: #1976d2;
}
.classAlign {
  display: flex;
  flex: 1 1;
  margin: 0 auto;
}
.borderS {
  border: 2px solid #0fff17;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px;
  border-radius: 18px;
}
.stackRe {
  color: #0fff17;
  font-size: 21px;
  padding-left: 28px;
}
.stackA {
  border: 8px solid #fff;
  border-radius: 25px;

  font-size: 16px !important;
  text-align: left;
  color: #09031c;
  font-weight: 700;
  overflow: hidden;
  position: relative;
}

.stackA .stack-value {
  background: #fff;
  height: 100%;
  position: absolute;
  background-position: right;
}
.bgRef .stackA .stack-value {
  background: #fff;
  height: 100%;
  position: absolute;
  background-position: right;
}
.stackA .stack-value span {
  font-size: 20px;
  display: flex;
  align-items: stretch;
  padding-left: 5px;
  font-family: "Poppins";
}

.btnTopH {
  color: #09031c;
  padding: 5px 15px;
  border-radius: 25px;
  font-weight: 700;
  background: #fff;
  border: 2px solid #fff;
  font-size: 26px;

  min-width: 260px;
}
.centerB {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.btnTop {
  color: #09031c;
  padding: 5px 53px;
  border-radius: 17px;
  font-weight: 700;
  border: 2px solid #1976d2;
  font-size: 14px;

  background: #1976d2;
  margin-top: 15px;

  background: #1976d2;
}
.ANTRect.ANTRect-active .valeurRectanglerefV {
  font-size: 14px !important;
}
.ANTProgress-numbers > span::after,
.ANTProgress-numbers-active > span::after {
  content: "";
  position: absolute;
  width: 6px;
  left: 50%;
  margin-left: -2px;
  height: 11px;
  background-color: transparent !important;
  top: 100%;
}
.ANTProgress-numbers > span,
.ANTProgress-numbers-active > span {
  height: 31px !important;
  width: 31px !important;
  margin-bottom: 10px !important;
  font-size: 18px !important;
}
.centerS {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 25px !important;
}

.bgB {
  padding: 15px 30px;

  background: #033c3c;
  background-color: #09031c;
  padding: 5em 2em;
  border-radius: 20px;
  margin-bottom: 15px;
}
.Cstaking h2 {
  font-size: 30px;
  color: #fff;
}

.ANTProgress-numbers,
.ANTProgress-numbers-active {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.BoxInfos {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  background: #00001e;
  padding: 35px 20px;
  border-radius: 10px;
  margin: 15px 0;
  text-align: center;
}

.BoxInfos > * {
  flex: 1;
}

.BoxInfos-title {
  font-size: 25px;
  color: #fff;
}

.Staking-text {
  color: #fff;
  font-size: 25px;
}

.btnslist {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.btnslist button {
  font-size: 20px;
}

.nowrap {
  white-space: nowrap;
}

.boxinfovalue {
  color: rgb(15, 255, 23);
}

.boxinfofont20 {
  font-size: 20px;
  text-align: center;
  display: grid;
  gap: 30px;
}

.boxinfolabel {
  display: inline-block;
  min-width: 150px;
}

.minwidth240 {
  min-width: 240px;
}
.btnTop2 {
  color: #fff;
  padding: 5px 15px;
  border-radius: 25px;
  font-weight: 700;
  border: 2px solid #fff;
  font-size: 26px;
  background: transparent;
  min-width: 260px;
}
.btnTop2:hover {
  color: #0fff17;
  border: 2px solid #0fff17;
}
.btnTopH:hover {
  background: #0fff17;
  border: 2px solid #0fff17;
}
.Cstaking {
  margin-top: 30px;
}
.alignSt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnClaim {
  background-color: #1976d2;
  border: 0;
  border-radius: 15px;
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
  padding: 5px 10px;
  min-width: 115px;
}
.btnClaim a {
  color: #fff !important;
}
.btnClaim i {
  margin-top: 5px;
  font-size: 1rem;
}
.btnClaim:disabled {
  background-color: #ebebeb !important;
  color: #958e8e !important;
  border: 2px solid #958e8e;
}

.bgBA {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
}
.tabStak,
.tabStakh {
  color: #fff;
  font-size: 18px;
  width: 100%;
}

.tabStak td,
.tabStakh td {
  padding: 10px 5px;
  font-size: 16px;
  min-width: 65px;
}
.tabStak button {
  color: #fff;
  padding: 5px;
  border-radius: 25px;
  font-weight: 700;
  border: 2px solid #fff;
  background: transparent;
  min-width: 130px;
  font-size: 14px;
}
.tabStak .trM:nth-child(odd) {
  background: #00001e;
}
.tabStak .btnA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tabStakh tr:nth-child(1) {
  background: #00001e;
}
.tabSc {
  overflow-x: auto;
}
.tabStak i {
  color: #fff;
  margin-right: 5px;
}
.tabStak .disabled {
  color: #686565 !important;
  margin-right: 5px;
  border: 2px solid grey !important;
}
.disabledIcon {
  color: #686565 !important;
}
.disabled {
  color: #686565 !important;
  margin-right: 5px;
  border: 2px solid grey !important;
}
.testb {
  color: #000;
  border-color: #fff;
  background: #fff;
}

.modalDeposit .modal-dialog {
  top: 0;
  max-width: 50%;
}
.modalConfirmClaim .modal-dialog {
  top: 0;
  /*max-width:50%;*/
}
.alignt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
/*TOOLTIP*/
.aligni {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tooltip {
  opacity: 1 !important;
}
.tooltip i {
  margin-left: 5px;
  font-size: 19px;
  color: #958e8e;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #7373ab;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  font-size: 12px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #7373ab transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.btnClaim:hover {
  background-color: #fff;
}
.tabStak button:hover {
  color: #fff;
  border: 2px solid #fff;
}
.tooltip {
  z-index: 1000;
}
.boxinfop {
  display: flex;

  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  background: #00001e;
  padding: 35px 20px;
  border-radius: 10px;
  margin: 15px 0;
  text-align: center;
}

.label-platformFeesAmount {
  display: block;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px 0px 5px;
  color: #fff;
  font-size: 12px;
  margin-top: 15px;
}
.label-ClaimFeesFees {
  display: block;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px 0px 5px;
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px;
}

.MuiTablePagination-select {
  font-size: 14px !important;
}

.MuiToolbar-root > div > span {
  font-size: 14px !important;
  color: #fff;
}
.tabalance tr:nth-child(odd) .table-cell {
  background-color: #00001e;
  color: #7373ab;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 16px;
}
.tabalance .table-cell {
  padding-left: 15px;
}

.MuiTableSortLabel-root {
  font-size: 16px !important;
}

.MTablePaginationInner-root-7 {
  display: flex;

  flex-wrap: wrap;
}
.tx-field-tables > a {
  color: #7373ab;
}

.MTableToolbar-root-8 {
  background-color: #108688;
}
.MuiPaper-root {
  color: #7373ab !important;
  background-color: transparent !important;
}
.MuiTableCell-root {
  border-bottom: 1px solid transparent !important;
}
table tr th {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 26px;
  text-align: center !important;
}
.MuiTablePagination-root,
.MuiSelect-icon,
.MTablePaginationInner-root-7,
.MuiIconButton-root.Mui-disabled,
.MuiTableSortLabel-root:hover,
.MuiSvgIcon-root {
  color: #fff !important;
}
.MuiPaper-elevation2 {
  box-shadow: none !important;
}
.aligP {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.alp {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.aligP table tr td:nth-child(2) {
  text-align: right !important;
}
.fee {
  align-self: center;
  padding: 5px 40px;
}
.claiclose {
  color: rgb(115, 115, 171) !important;
  border-color: rgb(115, 115, 171) !important;
  padding: 5px 25px !important;
}
.clai {
  color: #1976d2 !important;
  padding: 5px 25px !important;
}
.clai:hover,
.claiclose:hover {
  color: #000 !important;
}

.feesR .modal-dialog {
  top: 0 !important;
}
.feesR .modal-content {
  min-height: 465px;
}
.ant-pagination-jump-next {
  display: none !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #09031c !important;
  border: 1px solid #fff !important;
  border-radius: 10px !important;
  color: #fff !important;
}
.ant-select-arrow,
.ant-select-item {
  color: #fff !important;
}
.ant-select-dropdown {
  background-color: #09031c !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: #fff !important;
  font-weight: 600;
  background-color: rgb(115, 115, 171) !important;
}

input[type="range"].range-slider::-webkit-slider-thumb {
  background-color: #09031c !important;
}
.flouLogo {
  filter: blur(3rem);
}

.flouLogo2 {
  filter: blur(0.5rem);
}
.centerV {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.itemS {
  width: 25%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.rowD {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.colS {
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.spandesc {
  background-color: hsla(0, 0%, 100%, 0.1) !important;
  color: #fff !important;
  border-radius: 20px;
  padding: 10px;
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.13);
}
.spandesc span {
  color: #fff !important;
}
.cardab {
  padding: 10px;
  text-align: justify;
  font-weight: 600;
  margin-bottom: 20px;
}

/*Remove arrows from input type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tokenTa img {
  border-radius: 5px;
}
.modal-header {
  justify-content: center;
}
input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.balance {
  min-height: 170px;
  font-size: 36px;
}
.balance2 {
  min-height: 90px;
  font-size: 36px;
}
.balance .symbol {
  font-size: 20px;
}

.balance .error {
  font-size: 16px;
}

.sublink {
  color: inherit !important;
}
.sizeID{
  min-width: 80%;
}