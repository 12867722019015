.item {
  background-color: hsl(0deg 0% 100% / 10%);
  max-width: 100% !important;
}

.btn {
  font-size: 0.75rem;
}

.title {
  color: #ebd673 !important;
}
