.item {
  background-color: hsl(0deg 0% 100% / 10%);
  max-width: 100% !important;
}

.nft {
  cursor: pointer;
}

.loader-container {
  width: 100%;
  min-height: 20vh;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
